.sidebaropen {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 20px 10px;
  color: white;
  font-family: 'Montserrat', sans-serif;
  border-radius: 4px; }

.img-logo {
  width: 100%;
  height: 100%; }

.pointer {
  cursor: pointer; }

.sidebaropen {
  transition: all .6s linear;
  width: 100%;
  height: 100vh; }

.tx-white {
  color: white;
  padding: 15px 5px;
  font-weight: bolder; }

.tx-white:hover {
  cursor: pointer; }

.form-control {
  padding: 17px 25px !important;
  border-radius: 30px !important;
  background-color: #ffffff24 !important;
  color: white !important;
  border: none !important;
  margin: 20px 0; }

.sidebar-item {
  padding: 0 !important; }
  .sidebar-item li {
    display: inline-block; }

.img-logo-cover {
  width: 20%;
  margin-right: 10px; }

.list-group-item {
  background-color: transparent; }

.list-group-item.active {
  background-color: #51B74B; }

.list-group-item:hover {
  background-color: #51B74B;
  color: #51B74B; }

.list-group-item-action {
  color: #FFFFFF;
  padding: 15px 10px; }

.list-group-item-action.active {
  background-color: rgba(255, 255, 255, 0.2);
  font-weight: 600; }

.list-group-item-action:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF; }

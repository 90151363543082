.selected-date-range-btn {
  background: #51B74B;
  border-color: #1b984c; }

.selected-date-range-btn:hover {
  background-color: #1b984c;
  border-color: #51B74B; }

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #285d25;
  border-color: #51B74B; }

.selected-date-range-btn:not(:disabled):not(.disabled):active {
  background: #51B74B;
  border-color: #51B74B; }

.selected-date-range-btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 195, 97, 0.5); }

.selected-date-range-btn:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 195, 97, 0.5); }

.daterangepicker td.active {
  background-color: #1FB159; }

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #1FB159; }

.applyBtn {
  background-color: #1FB159;
  border-color: #1b984c; }

.applyBtn:hover {
  background-color: #1b984c;
  border-color: #51B74B; }

.applyBtn:not(:disabled):not(.disabled):active {
  background: #51B74B;
  border-color: #51B74B; }

.applyBtn:focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 195, 97, 0.5); }

.applyBtn:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 195, 97, 0.5); }

.daterangepicker .ranges li.active {
  background-color: #51B74B; }

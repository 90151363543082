body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(#0D2F43, #0C240B);
  position: relative;
  bottom: 0;
  width: 100%; }

.App-logo {
  animation: App-logo-spin infinite 20s linear; }

.App-header {
  background-color: #013042;
  min-height: 100vh; }

.App-link {
  color: #61dafb; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.vertical-timeline-element-title {
  font-weight: bold;
  font-size: 98%;
  color: #015c75; }

.vertical-timeline-element-content h2 {
  color: #015c75; }

.vertical-timeline-element-subtitle {
  font-size: 98%;
  margin: 0;
  color: #015c75;
  font-weight: 800; }

.vertical-timeline-element-paragraph {
  font-size: 14px !important;
  margin: 0.1em 0 0 0 !important; }

.vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
  left: auto !important;
  right: 138% !important;
  text-align: right !important;
  color: #2992d0 !important; }

.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
  position: absolute;
  width: 100%;
  left: 136% !important;
  top: 6px;
  font-size: 1rem;
  color: #2992d0; }

.stepName {
  text-align: center;
  padding: 10px 40px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  display: inline-block;
  border-radius: 15px; }

.container {
  margin: 0 100px !important; }

.row {
  margin-bottom: 20px; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

.react-bootstrap-daterangepicker-container .container {
  margin: 0 !important; }

.react-bootstrap-daterangepicker-container .row .col-md-4 {
  width: 100% !important;
  max-width: 100% !important;
  flex: 0 0 100% !important; }

.empty-container {
  margin: 25px 0; }
  .empty-container .icon {
    font-size: 45px;
    text-align: center;
    color: #51B74B; }
  .empty-container .text {
    font-size: 16px;
    text-align: center;
    color: #fff; }

/*Modal*/
.modal-title .time {
  color: #ffffff;
  display: block;
  text-align: left;
  font-size: 70%; }

.modal .close {
  font-size: 2.8rem; }

.modal .close:not(:disabled):not(.disabled):hover,
.modal .close:not(:disabled):not(.disabled):focus {
  color: #f5f5f5; }

/* Steps */
.form-steps {
  cursor: pointer;
  width: 100%;
  clear: both;
  margin-bottom: 20px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease; }

.form-steps:before, .form-steps:after {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: table;
  content: ""; }

.form-steps:after {
  clear: both; }

.form-steps__step {
  float: left;
  padding: 1em;
  text-align: center;
  position: relative; }

.form-steps__step:after {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  content: "";
  background: #015c75;
  height: .4em;
  top: 2.4em;
  left: 50%;
  width: 100%;
  display: block;
  position: absolute;
  z-index: 1; }

.form-steps__step:before {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  content: "";
  background: #0B2520;
  height: .4em;
  top: 2.4em;
  left: 50%;
  margin-left: -2em;
  width: 4em;
  display: block;
  position: absolute;
  z-index: 2; }

.form-steps__step--last:after {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none; }

.form-steps__step--active .form-steps__step-circle {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #1c6657;
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: #fff; }

.form-steps__step--completed:after {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #015c75; }

.form-steps__step--completed .form-steps__step-circle {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #51B74B;
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: #fff; }

.form-steps__step--completed .form-steps__step-circle .form-steps__step-number {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none; }

.form-steps__step--completed .form-steps__step-circle .form-steps__step-check {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: inline-block; }

.form-steps__step--failed:after {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #015c75; }

.form-steps__step--failed .form-steps__step-circle {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #E04646;
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: #fff; }

.form-steps__step--failed .form-steps__step-circle .form-steps__step-number {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none; }

.form-steps__step--failed .form-steps__step-circle .form-steps__step-check {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: inline-block; }

.form-steps__step--inactive:after {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #eee; }

.form-steps__step--inactive .form-steps__step-circle {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #555;
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: #000000; }

.form-steps__step--inactive .form-steps__step-circle .form-steps__step-number {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: inline-block; }

.form-steps__step--inactive .form-steps__step-circle .form-steps__step-check {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none; }

.form-steps__step-circle {
  background: #eee;
  border: 1px solid #bdbdbd;
  line-height: 3em;
  border-radius: 50%;
  height: 3em;
  width: 3em;
  text-align: center;
  color: #000000;
  margin: 0 auto;
  position: relative;
  z-index: 3; }

.form-steps__step-check {
  display: none;
  font-size: 18px;
  margin-top: -3px;
  margin-left: 2px; }

.form-step__step-name {
  margin-top: .8em;
  display: inline-block;
  font-size: 1em;
  line-height: 1.4em;
  color: #f5f5f5; }

.card-title {
  color: #fff;
  /*font-size: 1.3rem;*/
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 1.5rem; }

.card {
  background-color: #0B2520; }

.card-body {
  padding: 1rem 2rem; }

.style-card .card-title {
  background-color: #E04646;
  padding: 1.5rem 1rem; }

.login-card .card .style-card {
  background-color: #ffffff; }

.progress-bar-name {
  color: #fff;
  margin-bottom: 0.3rem;
  float: left;
  text-transform: capitalize; }

.progress-bar-value {
  color: #fff;
  margin-bottom: 0.3rem;
  float: right; }

.progress-bar-div {
  margin-bottom: 1rem; }

.progress {
  height: 0.6rem; }

.CircularProgressbar {
  height: 124px; }

.circular-progress-div {
  float: left;
  width: 50%;
  overflow: auto; }

.summary-text {
  float: right;
  color: #fff;
  width: 50%;
  overflow: auto; }

.card-text {
  color: #fff; }

.line {
  border: 1px solid #fff;
  margin: 7px 0; }

.text-above {
  margin-bottom: 0;
  text-align: center; }

.value-below {
  text-align: center;
  font-weight: bold;
  margin-bottom: 0; }

.card-icon {
  margin-right: 3px; }

.card-upper-body {
  margin-bottom: 3rem; }

.summary-slider {
  text-align: center;
  position: absolute;
  bottom: 2px;
  right: 0;
  left: 0; }

.summary-slide-pointer {
  color: #ffffff;
  cursor: pointer;
  font-size: 30px;
  font-weight: 900;
  padding: 0 5px; }

.summary-slide-pointer.active {
  color: #77e1fe; }

.modal-90w {
  width: 100%; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 93%;
    margin: 1.75rem auto; } }

.modal-content {
  background-color: rgba(11, 37, 32, 0.9); }

.modal .close {
  color: #fff;
  font-size: 2rem;
  text-shadow: 1px 1px #fff; }

.modal .close:focus {
  color: #fff;
  font-size: 2rem;
  text-shadow: 1px 1px #fff; }

.modal-header {
  border-bottom: none; }

.modal-title {
  color: #fff;
  text-align: center; }

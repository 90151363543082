.range-card {
  //padding: 3px 0;
  background: #015c75;
  border-radius: 20px;
  text-align: center;
  width: 70%;
}

.range-amt {
  font-weight: bold;
}
.tabs {
  list-style: none;
  padding: 0; }
  .tabs li {
    display: inline-block;
    background: #015c75;
    padding: 8px 30px;
    margin-right: 15px;
    color: #ffffff;
    font-size: 1rem;
    border-radius: 20px;
    text-transform: uppercase;
    margin-bottom: 0; }
    .tabs li.active {
      background: #51B74B; }
    .tabs li a {
      color: #ffffff;
      text-decoration: none; }

.tabs.secondary {
  border-bottom: solid 2px #193917;
  border-bottom: solid 2px #a6a6a6; }
  .tabs.secondary li {
    background: none;
    border-radius: 0; }
    .tabs.secondary li.active {
      margin-bottom: -3px;
      border-bottom: solid 4px #51B74B; }

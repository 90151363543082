@import "../../assets/css/colors.scss";
/* styles for button */

.btn-search {
    background: $primary-color;
    border-color: #1b984c;
}

.btn-search:hover {
    background-color: #1b984c;
    border-color: $primary-color;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: darken($primary-color, 25%);
    border-color: $primary-color;
}

.btn-search:not(:disabled):not(.disabled):active {
    background: $primary-color;
    border-color: $primary-color;
}

.btn-search:focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 195, 97, 0.5)
}

.btn-search:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 195, 97, 0.5)
}

/* styles for table component */

.table-vgg-darkblue th,
.table-vgg-darkblue td,
.table-vgg-darkblue thead th,
.table-vgg-darkblue tbody+tbody {
    border-color: #0b2520
}

.table-hover .table-vgg-darkblue:hover {
    background-color: #c2c2c2
}

.table-hover .table-vgg-darkblue:hover>td,
.table-hover .table-vgg-darkblue:hover>th {
    background-color: #c2c2c2
}

.table-vgg-darkblue {
    color: #fff;
    background-color: #0b2520
}

.table-vgg-darkblue th,
.table-vgg-darkblue td,
.table-vgg-darkblue thead th {
    border-color: rgba(0, 0, 0, 0.3)
}

.table-vgg-darkblue.table-bordered {
    border: 0
}

.table-vgg-darkblue.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05)
}

.table-vgg-darkblue.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075)
}

.table-vgg-darkblue,
.table-vgg-darkblue>th,
.table-vgg-darkblue>td {
    background-color: #0b2520
}

.table-hover .table-vgg-darkblue:hover,
.table-hover .table-vgg-darkblue:hover>th,
.table-hover .table-vgg-darkblue:hover>td {
    background-color: $secondary-color
}

.table-fixsquare-icon {
    color: $danger-color
}

.table-fixchecksquare-icon {
    color: $primary-color;
}

.failed-manifest-card .style-card .card-title {
    background-color: #E04646;
    padding: 1.5rem 1rem;
}

.manifest-list .style-card .card-title {
    background-color: inherit;
}

// row cursor pointer
cursor-pointer {
    cursor: pointer;
}
.img, .img-user {
  width: 100%;
  height: 100%; }

.pointer {
  cursor: pointer; }

.mg-tp-15 {
  margin-top: 15px; }

.pd-tp-50 {
  padding: 5px 0; }

.menu {
  cursor: pointer;
  color: white; }

.img-icon {
  width: 5%;
  margin: 10px 30px;
  cursor: pointer; }

.img-icon:nth-of-type(2) {
  padding: 0 12px; }

.nav-flex {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: flex-end; }

.img-user {
  border-radius: 100%; }

.img-user-cover {
  width: 12%;
  border-radius: 100%; }
  .img-user-cover img {
    width: auto;
    height: 36px;
    border-radius: 50%; }

.app-title {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
  color: #51B74B;
  padding: 0 20px;
  font-family: 'Montserrat', sans-serif; }

.username {
  color: #fff; }

.logout {
  color: #fff;
  margin: 8px 10px;
  cursor: pointer; }

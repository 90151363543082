.login-view .card {
    background-color: #ffffff;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

.login {
  height: 100vh;
  background: #E9EDF0 url("../../assets/image/mockuper5.png") no-repeat ;
  background-position:  100% 100%;
  background-size: 68%;
  overflow: hidden;

}

.login-view {
        height: 100vh;
    position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0; left: 0;
    height: 60%;
  }
  &:after {
    content: '';
    position: absolute;
    background: linear-gradient(#0D2F43, #0C240B);
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    webkit-clip-path: polygon(0% 0%, 100% 0, 100% 20%, 33% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 0, 100% 20%, 33% 100%, 0% 100%);
    opacity: 1;

  }
}

.login-view .style-card .title {
  background: linear-gradient(#2D7BA4, #0B2520);
  text-align: center;
  text-transform: uppercase;
  width: 70%;
  position: relative;
  left: 16%;
  top: -44px;
  padding: 1.2rem 1rem;
}

.login-view .form-control {
  //-webkit-border-radius: 20px !important;
  //-moz-border-radius: 20px !important;
  //border-radius: 20px !important;
  border-radius: 0 20px 20px 0 !important;
  color: #555!important;
  background-color: #fff!important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  margin-top: 6px;
}

.login-view .form-label {
  margin-bottom: 0!important;
}

.login-view .form-group {
  margin: 1.5rem 2rem;
}

.login-view .forgot-text {
  margin-top : -10px!important;
}

.button-container {
  text-align: center;
}

.login-button {
  text-align: center;
  background: linear-gradient(#2D7BA4, #0B2520);
  border: 1px solid #0B2520!important;
  padding: 2% 10%;
  //box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
  box-shadow: 0 0.5em 0.9em -0.4em #0B2520;

}

.login-button:hover .login-button:focus {
  box-shadow: 0 0.5em 0.5em -0.4em #ffa260;
  transform: translateY(-0.25em);
}

.small {
  font-size: 90%;
}

.login-view .form-group .input-group-prepend {
  padding: 0;
  height: 36px;
  margin-top: 6px;
  border-radius: 20px;
}

.login-error {
  text-align: center;
  color: red;
  font-weight: bold;
}

.login-form {
  position: relative;
  top: -34px;
}

@media only screen and  (max-width: 1024px) {
  .inputGroupPrepend {
    display: none;

  }

  .login-view .form-control {
    padding: 5px!important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
  }

  .input-group {
    width: 100%;

  }

  .login-view .card {
    width: 90%;
  }

  .login-view .form-group {
    margin: 1.5rem 0;
  }
}